import AxiosInstance from "../../utils/AxiosInstance";
import { UserDetailInterface } from "./models/UserDetailInterface";

const API_ROUTE = "users";
export const getUserList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getUserDetail = async (index: string) => {
  return await AxiosInstance.get(`/users/get-by-id/${index}`);
};

export const getUserInfo = async (index: string) => {
  return await AxiosInstance.get(`/users/get-by-id/${index}`);
};

export const createUser = async (data: UserDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/${API_ROUTE}`, data);
};

export const deleteUser = async (index: string) => {
  return await AxiosInstance.delete(`/users/delete-by-id/${index}`);
};
export const updateUser = async (index: string, data: UserDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/users/update/`, data);
};
export const updateUser2FA = async (data)=>{
  return await AxiosInstance.put(`/users/update-2fa/`,data);
}
