import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  ToggleButton,
  Tooltip,
} from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { getUserListAsync, UserState } from '../UserSlice';
import { deleteUser, updateUser, updateUser2FA } from '../UserAPI';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider, {
  Search,
  CSVExport,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { propTypes } from 'react-bootstrap/esm/Image';

/**
 * Usering list page
 */
const UserList = () => {
  const { users, status, totalRecord } = useAppSelector(UserState);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { SearchBar, ClearSearchButton } = Search;
  const [sort, setSort] = useState('created_at');
  const [order, setOrder] = useState('DESC');
  const [deleteItem, setDeleteItem] = useState('');
  const [_2faUser, set2faUser] = useState({ userId: '', _2fa: null });

  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const { ExportCSVButton } = CSVExport;

  useEffect(() => {
    getPageData();
  }, [dispatch, page, sort, order]);

  const getPageData = () => {
    const filter = {
      page: page,
      sortField: sort,
      order: order,
    };
    dispatch(getUserListAsync(filter));
  };

  const removeUser = async () => {
    const res = await deleteUser(deleteItem);
    if (res.data.status == 200) {
      toast.success('User deleted!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setDeleteItem('');
    getPageData();
  };

  const update2FAUser = async () => {
    const res = await updateUser2FA({
      user_id: _2faUser.userId,
      _2fa: _2faUser._2fa,
    });
    if (res.data.status == 200) {
      toast.success('2FA updated!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    set2faUser({ userId: '', _2fa: null });
    getPageData();
  };

  useEffect(() => {
    if (status == 'updated') {
      toast.success('User updated!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [status]);

  const customTotal = (from: any, to: any, size: any) => (
    <span className='react-bootstrap-table-pagination-total'>
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: 'All',
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const docFormatter = (cell, row, rowIndex) => {
    return cell.toLocaleString();
  };

  const columns = [
    {
      dataField: 'nick_name',
      text: 'User Name',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'media_credit',
      text: 'Media Credit',
      formatter: docFormatter,
      sort: true,
    },

    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      classes: 'col-2',
      formatter: (cellContent: any, row: any) =>
        row.status == 1 ? (
          <span className='label label-success rounded-pill'> Active </span>
        ) : (
          <span className='label label-danger rounded-pill'> Inactive </span>
        ),
      csvFormatter: (cellContent: any, row: any) =>
        row.status == 1 ? 'Active' : 'Inactive',
    },
    {
      dataField: '_2fa',
      text: '2FA',
      headerAlign: 'center',
      align: 'center',
      classes: 'col-1',
      formatter: (cellContent: any, row: any) => (
        <Form>
          <OverlayTrigger
            overlay={
              <Tooltip id={row.user_id}>
                {row._2fa ? 'Enabled' : 'Disabled'}
              </Tooltip>
            }
          >
            <Form.Check
              inline
              title={row._2fa ? 'Enabled' : 'Disabled'}
              type='switch'
              checked={row._2fa}
              id={row.user_id}
              className='custom-toggle'
              onChange={() => {
                set2faUser({ userId: row.user_id, _2fa: !row._2fa });
              }}
            />
          </OverlayTrigger>
        </Form>
      ),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      headerAlign: 'center',
      align: 'center',
      classes: 'col-1',
      formatter: (cellContent: any, row: any) => (
        <div className='d-flex justify-content-around'>
          <i
            className='feather icon-trash-2 text-danger fw-bold'
            onClick={() => {
              setDeleteItem(row.user_id);
            }}
          ></i>
          <i
            className='feather icon-edit text-success fw-bold'
            onClick={() => {
              navigate(`/users/update/${row.user_id}`);
            }}
          ></i>
          {/* <i
            className="feather icon-eye text-secondary fw-bold"
            onClick={() => {
              navigate(`/users/info/${row.user_id}`);
            }}
          ></i> */}
        </div>
      ),
      csvFormatter: (cell: any, row: any) => '',
    },
  ];

  const listData = users.filter((e: any) => {
    return (
      (e.nick_name?.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
        e.email?.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
        e.media_credit.toString().indexOf(searchText.toString()) >= 0) &&
      (searchStatus == '' || e.status == searchStatus)
    );
  });

  return (
    <>
      <div className='page-header'>
        <ToastContainer />
        <div className='page-block'>
          <div className='row align-items-center'>
            <div className='col-md-12'>
              <div className='page-header-title'>
                <h5 className='m-b-10'>User</h5>
              </div>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/'>
                    <i className='feather icon-home'></i>
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <a>Users</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='main-body'>
        <div className='page-wrapper'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card'>
                <div className='card-header'>
                  <Row>
                    <Col auto>
                      <h5>Users</h5>
                    </Col>
                    {/* <Col md={2}>
                      <Link className="btn btn-primary" to="/user/create">
                        Add User
                      </Link>
                    </Col> */}
                  </Row>
                </div>
                <div className='card-block table-border-style'>
                  {status === 'loading' ? (
                    <Spinner animation='border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  ) : status === 'failed' ? (
                    <Alert key={'danger'} variant={'danger'}>
                      Something went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField='id'
                        data={listData}
                        columns={columns}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <Col>
                              <input
                                placeholder='Search'
                                type={'text'}
                                value={searchText}
                                className='form-control'
                                onChange={(e) => {
                                  setSearchText(e.target.value);
                                }}
                              />
                            </Col>
                            <Col>
                              {' '}
                              <select
                                className='form-control '
                                onChange={(e) => {
                                  if (e.target.value == 'Inactive') {
                                    setSearchStatus('0');
                                  } else if (e.target.value == 'Active') {
                                    setSearchStatus('1');
                                  } else {
                                    setSearchStatus('');
                                  }
                                }}
                              >
                                <option selected={searchStatus == ''} value=''>
                                  Select Status
                                </option>
                                <option selected={searchStatus == '1'}>
                                  Active
                                </option>
                                <option selected={searchStatus == '0'}>
                                  Inactive
                                </option>
                              </select>
                            </Col>
                            <Col>
                              <button
                                onClick={() => {
                                  setSearchStatus('');
                                  setSearchText('');
                                }}
                                className='btn btn-primary'
                              >
                                Clear
                              </button>
                              <ExportCSVButton
                                {...props.csvProps}
                                className='btn-success fw-bold ms-2'
                              >
                                Export CSV
                              </ExportCSVButton>
                            </Col>
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal show={true} onHide={() => setDeleteItem('')}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete User?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setDeleteItem('')}>
              Close
            </Button>
            <Button
              className='btn-danger'
              variant='primary'
              onClick={removeUser}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {_2faUser.userId ? (
        <Modal
          show={true}
          onHide={() => set2faUser({ userId: '', _2fa: null })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm 2FA Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to update the user's Two-Factor Authentication
            (2FA) settings?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => set2faUser({ userId: '', _2fa: null })}
            >
              Close
            </Button>
            <Button
              className='btn-primary'
              variant='primary'
              onClick={update2FAUser}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
export default UserList;
